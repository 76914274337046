.b-error_page {
	margin: 0 auto;
	text-align: center;

	&-header {
		border-bottom: 1px solid #7f7f7f;
		padding: 32px;
	}

	&-container {
		margin: 72px auto 0;
		max-width: 960px;

		@include media(md) {
			padding: 0 20px;
		}

		@include media(sm) {
			margin-top: 32px;
			padding: 0 20px;
		}
	}

	&-title {
		@include g-heading_1;

		margin-bottom: 40px;
	}

	&-subtitle {
		font-size: 20px;
		font-weight: 300;
		margin: 0 auto 60px;
		max-width: 510px;
	}

	&-content p {
		margin: 40px 0;
	}

	&-service_link {
		cursor: pointer;
		text-decoration: underline;
		white-space: nowrap;

		&:hover {
			text-decoration: none;
		}
	}
}
