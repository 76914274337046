/*md
@no-stat

# Breakpoints

## Boilerplate breakpoints

Boilerplate has 4 main breakpoints that targeted to [supported devices](https://confluence.ontrq.com/display/RSB/SFRA+BP+-+Supported+Browsers+and+Devices)
 - iPhone X, iPad, MS Windows desktop / Macbook Pro retina

** Please not iPad landscape - is LG breakpoint **

[See more info](https://confluence.ontrq.com/display/RSB/SFRA+-+Site+Layout+Conception)

## Supported screen resolutions

Boilerplate is come "Retina ready". It supports MDPI and XHDPI pixel density across all site.

| Device             | Screen Resolution, CSS pixels | Pixel density |
|--------------------|-------------------------------|---------------|
| Desktop Windows PC | 1920x1080                     | MDPI          |
| Macbook pro 13     | 1280x800 / 1440x900           | XHDPI         |
| iPad Air 2         | 1024x768                      | XHDPI         |
| iPhone X           | 375x812                       | XHDPI         |
| Samsung Galaxy S9  | 360x740                       | XHDPI         |

*/
/*md
@no-stat

# Media queries (breakpoints)

We have a `media` mixin for make it easier to implement responsive styling via media queries.

You can nest them right within other blocks of CSS,which puts the properties and values you are changing right next
to each other.
That creates an obvious connection between them, which is a much nicer authoring experience than trying to maintain
those changes separated by tons of other code or in a different file.

## Configuration

**Site Layout Conception** details with examples you can find [here](https://confluence.ontrq.com/display/RSB/SFRA+-+Site+Layout+Conception)

`media` mixin works with `$media` map where `media-name: media query`

This is how `$media` map looks:

```scss
$media: (
	sm: 'screen and (max-width: 767px)',
	md: 'screen and (min-width: 768px) and (max-width: 1199px)',
	lg: 'screen and (min-width: 1200px)',
	xl: 'screen and (min-width: 1201px)',
	md-up: 'screen and (min-width: 768px)',
	md-down: 'screen and (max-width: 1023px)',
	lg-up: 'screen and (min-width: 1024px)',
	lg-down: 'screen and (max-width: 1367px)'
);
```

## Usage

Here is how to use `media()` mixin:

```scss
.b-block {
	// styles outside of a media query

	@include media(sm) {
		// styles for "s" viewports
	};

	@include media(md-up) {
		// styles for "m" and "l" viewports
	};
}
```

Simply edit this file and add your own media queries to `$media` map.

*/
/*md
@no-stat

# Palette

This is palette settings for project/brand. It divided into 2 main categories palette and applied color.

* Palette is general set of colors. It could be used directly if you do not have themes.
* Applied colors designed as layer of abstraction to have ability to overwritten on brand level.

*/
/*md
@no-stat

# Globals variables

This variables are set of different global settings that is used across sets of components.

It include:

* globals
* depth of components (box-shadow)
* motion of components

*/
/*md
@no-stat

# Rh (Indents rhythm)

This function is designed to keep consistency of vertical and horizontal indents in the project.

Not all values are identical in design, sometimes 20px become 21px, 19px, 22px etc. It does not make
any sense to implement it as is. To keep indents consistent we need to round this values to 4px steps.

Ex: in design 22px / 19px -> rh(5) => 20px; in design 23px -> rh(6) => 24px etc.

This is alternate approach to `$space-md: 10px; $space-lg: 20px;`.

Designers should use the rhythm in his work.

## Usage

```scss
.component {
	@include rh(2); // => 8px
	@include rh(2 4 0); // => 8px 16px 0
}
```
*/
/*md
@no-stat

# Z-indexes

Z-index is an inherently tricky thing, and maintaining z-index order in a complex layout is difficult.
With different stacking orders and contexts, keeping track of them as their numbers increase can be hard.
<br />
<br />
We use sass function to help manage z-indexes from single place.
The most important requirement of this technique is sticking to it.
Any rogue hard-coded z-index values could compromise the integrity of those derived from your list.

## Usage

**We don't use hardcoded integer `z-index` values. Instead, we use indexes from the map `$z-indexes`**

### 1. Set up `$z-indexes` map
```scss
$z-indexes: (
    components: (
        component_name: (),
        checkbox: (
            before: (),
            after: (),
            icon: (),
        )
    ),
    content: (),
    popup-menu: ()
);
```

### 2. Add values in SCSS classes using `z()` function

#### Global components
```scss
.b-components { z-index: z(components); }
.b-content { z-index: z(content); }
.b-pop_up-menu { z-index: z(popup-menu); }
```

#### Inside a component
```scss
.b-component_name { z-index: z(components, component_name); }
.b-checkbox {
    &-before { z-index: z(components, checkbox, before); }
    &-after { z-index: z(components, checkbox, after); }
    &-icon { z-index: z(components, checkbox, icon); }
}
```

### 3. Get resulting CSS
```scss
.b-components { z-index: 1; }
.b-content { z-index: 2; }
.b-pop_up-menu { z-index: 3; }

.b-component_name { z-index: 1; }
.b-checkbox-before { z-index: 1; }
.b-checkbox-after { z-index: 2; }
.b-checkbox-icon { z-index: 3; }
```

*/
/*md
@no-stat

# Grids

## How to setup grids config for project

### Several grid configs for project

We can use several grid configs per project. For that, we need to add a new grid name to the `$grids` map with settings.

### Gaps / margin / column span configuration:

```scss
$grids: (
	default: (
		grid-columns: ('xl': 12,   'lg': 12,   'md': 12,   'sm': 6),
		grid-gutter:  ('xl': 20px, 'lg': 20px, 'md': 16px, 'sm': 9px),
		grid-margin:  ('xl': 88px, 'lg': 60px, 'md': 32px, 'sm': 15px),
	)
);
```

### Grid-span configuration

Please see details [grid-span](01-core-functions-grid-span.html)

## Work with grids

### Development approaches

#### 1. Using `g-grid` mixin

With features of `display: grid`. Please see [g-grid](02-components-g-grid.html) details.

#### 2. Using `grid-span` function

Could be used in conjunction with different display properties while maintaining their common features(floating, centering, etc.). Please see [grid-span](01-core-functions-grid-span.html) details.

### Get gaps / margin / column span

For that we have the next grid functions in `_grids_tools.scss`:
- grid-gutter
- grid-margin
- grid-columns

Please see [grid functions](00-configuration-grids_tools.html) details with usage examples.

### Examples of usage

Please see [ready-made tools](05-blocks-guide-l-cols.html) details.

*/
/*md
@no-stat

# grid-* (grid config get functions)

This functions designed to get parameters from grid configuration config and
use it for creating grids or reuse grid configuration into different components.

* `grid-gutter`
* `grid-columns`
* `grid-margin`

## Usage

```scss

// Configuration:

$grids: (
	default: (
		grid-columns: ('xl': 12,   'lg': 12,   'md': 12,   'sm': 6),
		grid-gutter:  ('xl': 20px, 'lg': 20px, 'md': 16px, 'sm': 9px),
		grid-margin:  ('xl': 88px, 'lg': 60px, 'md': 32px, 'sm': 15px),
	),
	altered: (
		grid-columns: ('xl': 10,   'lg': 10,   'md': 10,   'sm': 6),
		grid-gutter:  ('xl': 10px, 'lg': 10px, 'md': 10px, 'sm': 10px),
		grid-margin:  ('xl': 40px, 'lg': 30px, 'md': 30px, 'sm': 20px),
	)
);

// Usage:

.component {
	padding: grid-gutter('lg'); // => grids -> 'default' -> grid-gutter -> lg = 20px
	padding: grid-gutter('lg', 'altered'); // => => grids -> 'altered' -> grid-gutter -> lg = 10px
}

.component-b {
	margin: grid-margin('lg');
	margin: grid-margin('lg', 'altered');

	@include media(sm) {
		margin: grid-margin('sm');
	}
}

.component-c {
	width: percentage(grid-columns('lg') / 4);

	@include media(sm) {
		width: percentage(grid-columns('sm') / 2);
	}
}
```
*/
/*md
@no-stat

# adjust-color-to-bg

This function used to adjust color depending on provided background color. It use
luminance human persived criteria as breakpoint for colors
[See more details](http://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef).

It is especially useful for crating flexible themes.

## Arguments

```
$backgroundColor - bg color
$colorInverse - color if bg is dark. If not provided would return $color-white
$colorNormal - color if bg is light. If not provided would return $color-text

adjust-color-to-bg($backgroundColor, $colorInverse, $colorNormal)
```

## Usage

```scss
.component {
	color: adjust-color-to-bg($color-bg-header-line-1);

	// => results default 'white' if background dark
	// => results default 'black' if background is light
}

.component-custom-inverse-color {
	color: adjust-color-to-bg($color-bg-footer, grey);

	// => results 'grey' if background dark
	// => results default 'black' if background is light
}

.component-all-custom-colors {
	color: adjust-color-to-bg($color-bg-footer, green, red);

	// => result 'green' if background dark
	// => result 'red' if background is light
}
```

Based on Hugo Giraudel [work](https://css-tricks.com/snippets/sass/luminance-color-function/)
*/
/*md
@no-stat

# grid-span

`grid-span` function returns value which could be used as **width, max-witdth, flex-basis, etc.**

### Parameters
```scss
@function grid-span($column: 1, $break: 'lg', $with-gutter: false, $grid: 'default')
```

## Examples

### Flex-basis example

```scss
.b-grid {
	display: flex;

	.b-grid__item {
		flex-basis: grid-span($column: 3);
	}
}
```

### Floated items example

```scss
.b-grid {
	.b-grid__item {
		float: left;
		width: grid-span($column: 2);
	}
}
```

### Inline-block items example

```scss
.b-grid {
	.b-grid__item {
		display: inline-block;
		max-width: grid-span($column: 2);
	}
}
```

*/
/*md
@no-stat

# aspect-ratio

This function used to get percentage value of aspect ratio color to use in `padding` to
create container for images.

This technique used to prevent content bouncing during load and create layout shifts.

Calculation. 16:9 Aspect Ratio would result `(9 / 16) * 100 = 0.5625%`.

See proposed [specs](https://drafts.csswg.org/css-sizing-4/#aspect-ratio)

## Arguments

```
$width - width of element
$height - height of element

=> percentage

aspect-ratio($width, $height)
```

## Usage

```scss
.component {
	padding-bottom: aspect-ratio(16, 9);
	padding-bottom: aspect-ratio(1920, 1080);
	padding-bottom: aspect-ratio(1920px, 1080px);
}

.b-suggestions-item_image {
	@include g-image_container(_container, aspect-ratio(16, 9));

	img {
		@include g-image_container(_img);
	}
}
```

*/
/*md
@no-stat

# Hide

This mixin is especially useful for hiding text
or visually hide needed elements

Here is a list of parameters you can use:

* text - helps to hide text without loosing visibility for parsers
* visually - like for text but for the whole element

## Usage

```scss
.component {
	@include hide(visually);
}

.h-hide_vis {
	@include hide(visually, true);
}
```
*/
.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

/*md
@no-stat

# Hover-supported

This mixin is designed to address iOS standard behavior of first tap - hover,
second tap - click that is engaged when control has hover styles applied.

This is critical for functionality like back-top-button. If we apply hover styles as is.
It would be activated only after second tap.

If rules are wrapped into this media it applied only in case if device have fine
pointer mechanism. [See more info](https://developer.mozilla.org/en-US/docs/Web/CSS/@media/pointer).

Please note about mixed input devices - touch screen + mouse + touchpad,
touchpad + trackpoint, touch screen + stylus ("apple pencil") etc. -
sometimes browser do not report it properly, so logic should be builded around
exclusions.

## Usage

```scss
.component {
	@include hover-supported {
		&:hover {
			// Hover styles that should not be applied to touch
		}
	};
}
```
*/
/*md
@no-stat

# RTL selector

This mixin is designed to alter styles for RTL languages.

It mostly needed for alter position:absolute left|right coords, but could be used
as facade for different selectors.

## Usage

```scss
.component {
	left: 0;
	@include rtl {
		left: initial;
		right: 0;
	};
}
```
*/
/* stylelint-disable selector-no-vendor-prefix */
/*md

# g-button

Designed to provide same styles of buttons across different components.
It is possible to use with `<button>` or `<a>` elements

## First type button

```html_example
<button type="submit" class="b-button">
	Sign in
</button>
```

## First type disabled button

```html_example
<button type="submit" class="b-button m-disabled">
	Sign in
</button>
```

## First type, full width button

```html_example
<button type="submit" class="b-button m-width_full">
	Sign in
</button>
```

## Second type button

```html_example
<button type="submit" class="b-button m-outline">
	Sign in
</button>
```

## Second type disabled button

```html_example
<button type="submit" class="b-button m-outline m-disabled">
	Sign in
</button>
```

## Second type, full width button

```html_example
<button type="submit" class="b-button m-outline m-width_full">
	Sign in
</button>
```

## Link button

```html_example
<button type="submit" class="b-button m-link">
	Sign in
</button>
```

## Medium height Link button

```html_example
<button type="submit" class="b-button m-link m-medium">
	Sign in
</button>
```

## Small height Link button

```html_example
<button type="submit" class="b-button m-link m-small">
	Sign in
</button>
```

*/
/*md

# g-button_iconed

Designed to provide same styles of buttons that contain only icon (without any text)
across different components without explicit CSS class.

It is used for header menubar icons, hamburger menu items and dialog close button.

```scss
.b-dialog {
	// ...
	&-close {
		@include g-button_iconed;
	}
}
```
*/
/*md

# g-radio

The component is generally used for choosing item which includes in the radio group.

## Usage

Only one g-radio in a given group can be selected at the same time.

If user selects one option in the list that other options come to unselected.

`g-radio` has states: unchecked, checked, hover, disabled and invalid.

## Unchecked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-2" class="b-radio-input"/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-2">Some text</label>
</div>
```

## Checked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-3" class="b-radio-input" checked/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-3">Some text</label>
</div>
```

## Disabled unchecked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-4" class="b-radio-input" disabled/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-4">Some text</label>
</div>
```

## Disabled checked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-5" class="b-radio-input" checked disabled/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-5">Some text</label>
</div>
```

## Invalid unchecked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-6" class="b-radio-input m-invalid"/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-6">Some text</label>
</div>
```

## Invalid checked

```html_example
<div class="b-radio">
	<input type="radio" id="id-radio-7" class="b-radio-input m-invalid" checked/>
	<div class="b-radio-icon"></div>
	<label class="b-radio-label" for="id-radio-7">Some text</label>
</div>
```

## Customization by SCSS

Radio button styles that used in several component.

Designed to use same style of radio in different components
ex: b-radio, b-payment_option, b-shipping_option, b-stores etc.

It provide styles only for icon element based on input node.

```scss_example
.b-option_switch {
	// ...
	&-input {
		@include g-radio(_input);
	}

	&-icon {
		@include g-radio(_icon);

		.b-option_switch-input:active + & {
			@include g-radio(_icon, m-active);
		}

		.b-option_switch-input:hover + & {
			@include g-radio(_icon, m-hover);
		}

		.b-option_switch-input:checked + & {
			@include g-radio(_icon, m-checked);
		}

		.b-option_switch-input[disabled] + & {
			@include g-radio(_icon, m-disabled);
		}
	}
}
```
*/
/*md

# g-checkbox

This component allows user to choose between two mutually exclusive state (checked or unchecked).

## Usage

A `g-checkbox` is used for select or unselect action items.

It is always tied to `<label>` that describes two opposite states.

The component usually points to choose settings or preferences.

`g-checkbox` has states (unchecked, checked, hover, disabled and invalid).

## Unchecked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-1" />
	<svg class="b-checkbox-icon" width="24" height="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-1">Some text</label>
</div>
```

## Checked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-2" checked/>
	<svg class="b-checkbox-icon" width="24" height="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-2">Some text</label>
</div>
```

## Disabled unchecked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-3" disabled/>
	<svg class="b-checkbox-icon" width="24" height="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-3">Some text</label>
</div>
```

## Disabled checked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input" type="checkbox" id="id-checkbox-4" checked disabled/>
	<svg class="b-checkbox-icon" width="24" height="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-4">Some text</label>
</div>
```

## Invalid unchecked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input m-invalid" type="checkbox" id="id-checkbox-5"/>
	<svg class="b-checkbox-icon" width="24" height="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-5">Some text</label>
</div>
```

## Invalid checked
```html_example
<div class="b-checkbox">
	<input class="b-checkbox-input m-invalid" type="checkbox" id="id-checkbox-6" checked/>
	<svg class="b-checkbox-icon" width="24" height="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path class="b-checkbox-icon_check" fill="none" stroke="currentColor" d="m5.2686 10.371 5.1528 6.9837 9.8939-11.913"/>
	</svg>
	<label class="b-checkbox-label" for="id-checkbox-6">Some text</label>
</div>

```

## Customization by SCSS

Checkbox styles that used in several component.

Designed to use same style of checkbox in different components without additional CSS class.
ex: `b-checkbox`, `b-refinement_checkbox`, `b-account_preference` etc.

It provide styles only for icon element based on SVG.

```scss
.b-refinement_checkbox {
	// ...
	&-icon {
		@include g-checkbox(_icon);

		.b-refinement_checkbox:active & {
			@include g-checkbox(_icon, m-active);
		}

		.b-refinement_checkbox.m-checked & {
			@include g-checkbox(_icon, m-checked);
		}

		.b-refinement_checkbox.m-disabled & {
			@include g-checkbox(_icon, m-disabled);
		}
	}
}
```
*/
/*md

# g-spinner

Global spinner component applied to different blocks that fetch data.

Designed to use same style of spinner in different components and on particular breakpoints.
Ex: b-minicart_popup, b-suggestions, b-plp_grid, b-product_details, b-cart etc.

```scss
.b-product_gallery {
	&.m-loading_long::before {
		@include g-spinner();
	}
	// ...
}
```
*/
/*md

# g-link

Designed to provide same styles of text-type links across different components.

## Usage

```scss
.component-link {
	@include g-link;
}

.component-link {
	@include g-link();
}
```

*/
/*md

# g-link_hamburger

Hamburger menu generic link that used in several component.

Designed to use same style of hamburger link in different components
ex: menu, account link, language switcher etc.

```scss
.b-menu {
	// ...
	&-item {
		@include media(sm) {
			@include g-link_hamburger;
		}
	}
}
```
*/
/*md

# g-icon_gradient

Designed to provide same styles of icon gradient across different components without
explicit CSS class.

```scss
.b-user_greeting {
	// ...
	&-icon {
		@include g-icon_gradient;
	}
}
```
*/
/*md

# g-image_container

This is global component designed to hold image in place and preventing from layout bouncing during page load.

It based on `padding-bottom` trick. `padding-bottom` and `padding-top` relative units are based
on parent element `width`. So if you had an element that is 500px wide, and padding-top of 100%,
the padding-top would be 500px. [More info](https://css-tricks.com/aspect-ratio-boxes/)

```scss
.b-suggestions-item_image {
	@include g-image_container(_container, 100%);

	img {
		@include g-image_container(_img);
	}
}

.b-suggestions-item_image {
	@include g-image_container(_container, 100%);

	img {
		@include g-image_container(_img);
	}
}
```

You could change aspect ration in mixin:

```scss
@include g-image_container(_container, 100%);   // 1:1
@include g-image_container(_container, 150%);   // 2:3
@include g-image_container(_container, 133%);   // 3:4
@include g-image_container(_container, 125%);   // 5:4
@include g-image_container(_container, 75%);    // 4:3
@include g-image_container(_container, 66.6%);  // 3:2
@include g-image_container(_container, 56.25%); // 16:9
```

But it is preferable to define only one aspect ration through all images and not change it.

*/
/*md

# g-snap_scroll

Snap scroll functionality applied to different cases.

Designed to use same snap scroll functionality in different components and on particular breakpoints.
Ex: b-carousel, b-product_gallery, .b-product_slider etc.

```scss
.b-product_gallery {
	&-thumbs_track {
		@include g-snap_scroll($direction: y);
	}
	// ...
}
```

[Snap scroll MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Scroll_Snap)

*/
/*md

# g-dialog_backdrop

Dialog window backdrop styles that used in several component.

Designed to use same style of backdrop and logic in different components and on particular breakpoints.
Ex: b-dialog, b-menu_panel, b-refinements_panel, b-minicart_panel etc.

```scss
.b-menu_panel {
	@include media(sm) {
		@include g-dialog_backdrop;
	}
	// ...
}
```
*/
/*md

# g-section_holder

This is global component designed to hold some standalone section of the site
as it wrapped into main container.

It could be used not only for standalone blocks, but also for page layouts.

```scss
.b-section {
	background: green;

	&-inner {
		@include g-section_holder;
	}
}
```
*/
/*md

# g-section_holder_header

Since header is differs from other container (g-section_holder)
we need special component the same as `section_holder` but with different
`max-width` and `margin`s.

This is global component designed to hold header of the site as it wrapped into
main container.

This common designs it could be removed and changed to `section_holder`.

```scss
.l-header-inner {
	background: green;

	&-inner {
		@include g-section_holder_header;
	}
}
```
*/
/*md

# g-heading_*

Some basic simple typography applied to different UI components.

This covers only very basic cases and could be extended.

```scss
.b-cart_empty {
	// ...

	&-title {
		@include g-heading_1;

		margin-bottom: rh(8);
	}
}
```
*/
/*md

# g-accordion

Global accordion component

## Attributes

```
data-allow-toggle="true" - Flag that allow or dissallow toggle
data-open-first="true" - Flag that open first item
data-allow-multiple="true" - Flag that allow or dissallow multiple open items
```

## Simple accordion example

```html_example
<div
    data-id="descriptions"
    data-widget="accordion"
    data-allow-toggle="false"
    data-open-first="true"
    data-allow-multiple="false"
    class="b-accordion"
>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="product-details-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 1</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="product-details"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				<p>
					Long content for first item. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim bibendum neque in pellentesque. Nulla nunc sem, lacinia vitae sapien ac, blandit cursus odio. Praesent et elit condimentum, varius ligula id, ullamcorper neque.
				</p>
				<p>
					Vivamus in nulla quis nulla dapibus dictum. Aenean eu turpis et felis luctus eleifend. In ut pharetra metus. Praesent sed fringilla mauris. Donec dignissim, urna cursus euismod varius, nunc urna aliquam neque, eu posuere elit ex mollis enim. Nulla sollicitudin scelerisque faucibus. Donec porta vestibulum felis ac molestie.
				</p>
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 2</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for second item
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 3</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for third item
			</div>
		</div>
	</section>
</div>
```

## Accordion with multiple open items

```html_example
<div
    data-id="descriptions"
    data-widget="accordion"
    data-allow-toggle="true"
    data-open-first="false"
    data-allow-multiple="true"
    class="b-accordion"
>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="product-details-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 1</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="product-details"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				<p>
					Long content for first item. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim bibendum neque in pellentesque. Nulla nunc sem, lacinia vitae sapien ac, blandit cursus odio. Praesent et elit condimentum, varius ligula id, ullamcorper neque.
				</p>
				<p>
					Vivamus in nulla quis nulla dapibus dictum. Aenean eu turpis et felis luctus eleifend. In ut pharetra metus. Praesent sed fringilla mauris. Donec dignissim, urna cursus euismod varius, nunc urna aliquam neque, eu posuere elit ex mollis enim. Nulla sollicitudin scelerisque faucibus. Donec porta vestibulum felis ac molestie.
				</p>
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 2</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for second item
			</div>
		</div>
	</section>
	<section
		data-widget="accordionItem"
		data-widget-event-closeallitems="closeItems"
		data-widget-event-accordionitemupdate="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent="togglePanel"
				data-event-keydown="handleKeydown"
				data-event-focus="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 3</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for third item
			</div>
		</div>
	</section>
</div>
```

## Init accordion on sm, md & lg devices

### Attributes

```
.b-accordion
	data-widget="accordion" - init for all viewports
	data-widget.sm="accordion" - init for sm viewports
	data-widget.sm.md="accordion" - init for sm & md viewports
	data-widget.sm.md.lg="accordion" - init for sm & md & lg viewports
.b-accordion-item
	data-widget="accordionItem" - init for all viewports
	data-widget.sm="accordionItem" - init for sm viewports
	data-widget.sm.md="accordionItem" - init for sm & md viewports
	data-widget.sm.md.lg="accordionItem" - init for sm & md & lg viewports
```

```html_example
<div
    data-id="descriptions"
    data-widget.sm.md.lg="accordion"
    data-allow-toggle="true"
    data-open-first="true"
    data-allow-multiple="true"
    class="b-accordion"
>
	<section
		data-widget.sm.md.lg="accordionItem"
		data-widget-event-closeallitems.sm.md.lg="closeItems"
		data-widget-event-accordionitemupdate.sm.md.lg="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="product-details-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent.sm.md.lg="togglePanel"
				data-event-keydown.sm.md.lg="handleKeydown"
				data-event-focus.sm.md.lg="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 1</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="product-details"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				<p>
					Long content for first item. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim bibendum neque in pellentesque. Nulla nunc sem, lacinia vitae sapien ac, blandit cursus odio. Praesent et elit condimentum, varius ligula id, ullamcorper neque.
				</p>
				<p>
					Vivamus in nulla quis nulla dapibus dictum. Aenean eu turpis et felis luctus eleifend. In ut pharetra metus. Praesent sed fringilla mauris. Donec dignissim, urna cursus euismod varius, nunc urna aliquam neque, eu posuere elit ex mollis enim. Nulla sollicitudin scelerisque faucibus. Donec porta vestibulum felis ac molestie.
				</p>
			</div>
		</div>
	</section>
	<section
		data-widget.sm.md.lg="accordionItem"
		data-widget-event-closeallitems.sm.md.lg="closeItems"
		data-widget-event-accordionitemupdate.sm.md.lg="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent.sm.md.lg="togglePanel"
				data-event-keydown.sm.md.lg="handleKeydown"
				data-event-focus.sm.md.lg="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 2</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for second item
			</div>
		</div>
	</section>
	<section
		data-widget.sm.md.lg="accordionItem"
		data-widget-event-closeallitems.sm.md.lg="closeItems"
		data-widget-event-accordionitemupdate.sm.md.lg="updateFocusedItem"
		class="b-accordion-item"
	>
		<h2>
			<button
				id="delivery-and-returns-btn"
				data-ref="accordionItemBtn"
				data-event-click.prevent.sm.md.lg="togglePanel"
				data-event-keydown.sm.md.lg="handleKeydown"
				data-event-focus.sm.md.lg="handleFocus"
				class="b-accordion-button"
				type="button"
			>
				<span>Title 3</span>
				<span class="b-icon_chevron"></span>
			</button>
		</h2>
		<div
			id="delivery-and-returns"
			data-ref="accordionItemPanel"
			class="b-accordion-content"
		>
			<div class="b-accordion-content_inner" data-ref="accordionItemPanelInner">
				Content for third item
			</div>
		</div>
	</section>
</div>
```

## Customization by SCSS

This implementation allow to use accordion for one vieport and any other component for rest viewports

```scss
.b-accordion {
	@include g-accordion;

	&-item {
		@include g-accordion(_item);
	}

	&-title {
		@include g-accordion(_control);
	}

	&-content {
		@include g-accordion(_content);

		&[aria-hidden='false'] {
			@include g-accordion(_content, expanded);
		}
	}

	&-content_inner {
		@include g-accordion(_content_inner);
	}
}
```
*/
/*md

# g-grid

Grid layout component based on CSS grid.

It is designed to easy use project defined grid into components where CSS grid is
applicable.

```scss
.b-grid {
	@include g-grid();

	.b-columns__item {
		@include media(lg-up) {
			grid-column: 2 / span 4;
			grid-row: 1 / 2;
		}

		@include media(md-down) {
			grid-column: grid-start / span 7;
		}
	}
}
```
*/
.b-server_error {
  margin: 0 auto;
  max-width: 1366px;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 56px;
  margin-top: 56px;
  text-align: center;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-server_error {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 767px) {
  .b-server_error {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.b-server_error-title {
  font-size: 39px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 32px;
  text-transform: none;
}
.b-server_error-link {
  color: inherit;
  cursor: pointer;
  text-underline-offset: 4px;
  text-decoration: none;
  display: inline-block;
  font-weight: 300;
  text-decoration: underline;
}
@media not all and (pointer: coarse) {
  .b-server_error-link:hover {
    text-decoration: underline;
  }
}
.b-server_error-link:hover {
  text-decoration: none;
}
.b-server_error-message {
  font-size: 20px;
  margin-bottom: 16px;
}

.b-error_page {
  margin: 0 auto;
  text-align: center;
}
.b-error_page-header {
  border-bottom: 1px solid #7f7f7f;
  padding: 32px;
}
.b-error_page-container {
  margin: 72px auto 0;
  max-width: 960px;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .b-error_page-container {
    padding: 0 20px;
  }
}
@media screen and (max-width: 767px) {
  .b-error_page-container {
    margin-top: 32px;
    padding: 0 20px;
  }
}
.b-error_page-title {
  font-size: 39px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 40px;
}
.b-error_page-subtitle {
  font-size: 20px;
  font-weight: 300;
  margin: 0 auto 60px;
  max-width: 510px;
}
.b-error_page-content p {
  margin: 40px 0;
}
.b-error_page-service_link {
  cursor: pointer;
  text-decoration: underline;
  white-space: nowrap;
}
.b-error_page-service_link:hover {
  text-decoration: none;
}

body {
  overflow: auto;
  overflow-y: scroll;
  pointer-events: all;
  visibility: var(--page_visibility, hidden);
}