.b-server_error {
	@include g-section_holder;

	margin-bottom: 56px;
	margin-top: 56px;
	text-align: center;

	&-title {
		@include g-heading_1;

		margin-bottom: 32px;
		text-transform: none;
	}

	&-link {
		@include g-link;

		display: inline-block;
		font-weight: 300;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	&-message {
		font-size: 20px;
		margin-bottom: 16px;
	}
}
